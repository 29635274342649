import React from 'react'

const Form = () => {
  const formSubmit = () => {}
  return (
    <form className="text-start" onSubmit={formSubmit}>
      <div className="mb-3">
        <label htmlFor="client-name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="client-name"
          required
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="email-address" className="form-label">
          Email address
        </label>
        <input
          type="email"
          className="form-control"
          id="email-address"
          aria-describedby="emailHelp"
          required
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="phone-number" className="form-label">
          Phone number
        </label>
        <input
          type="text"
          className="form-control"
          id="phone-number"
          required
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="query-text" className="form-label">
          What can we help with today?
        </label>
        <textarea
          style={{ resize: 'none' }}
          className="form-control"
          rows="6"
          id="query-text"
          required
        ></textarea>
      </div>
      <button type="submit" className="btn links">
        Submit
      </button>
    </form>
  )
}

export default Form
